import React from 'react';
import "./GreenBtn.css";
import { Button } from 'react-bootstrap';

export default function GreenBtn(props) {
  return (
    <div>
      <Button id='green-btn' className= 'text-decoration-none text-capitalize fw-semibold' href={props.btnLink}>
        {props.btnTitle}
      </Button>
    </div>
  )
}
