import './App.css';
import {Routes, Route, useLocation, Navigate  } from 'react-router-dom';
import Home from './Pages/Home/Home'
import Holidays from './Pages/Holidays/Holidays';
import Flights from './Pages/Flights/Flights';
import Hotels from './Pages/Hotels/Hotels'
import Buses from './Pages/Buses/Buses';
import NavBar from './Components/NavBar/NavBar';
import Footer from './Components/Footer/Footer';
import SearchResult from './Pages/SearchResult/SearchResult';
import Login from './Pages/Login/Login'
import Register from './Components/Register/Register';
import AndamanGroupPage from './Pages/AndamanGroupPage/AndamanGroupPage';
import AndamanCustomizedPage from './Pages/AndamanCustomizedPage/AndamanCustomizedPage';
import ErrorPage from './Pages/ErrorPage/ErrorPage';
import ViewAll from './Components/ViewAll/ViewAll';
import {useState, useEffect} from 'react';

function App() {
  
    function Shownnav(){
      const location = useLocation();
      if (location.pathname === '/login' || location.pathname === '/register'){
        return false
      }
      else{
        return true
      }
    }
    const [isTop, setIsTop] = useState(true);

    useEffect(() => {
      const handleScroll = () => {
        setIsTop(window.scrollY === 0);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    

  return (
    <div className="App">
      {Shownnav() && <NavBar isTop={isTop}/>}
        <Routes>
            <Route path='/login' element={<Login/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/' element={<Home/>} />
            <Route path='/flights' element={<Flights/>} />
            <Route path='/buses' element={<Buses/>} />
            <Route path='/hotels' element={<Hotels/>} />
            <Route path='/holidays' element={<Holidays/>} />
            <Route path='/search' element={<SearchResult/>}/>
            <Route path="/date/:date" element={<AndamanGroupPage/>} />
            <Route path="/customized/:id" element={<AndamanCustomizedPage/>} />
            <Route path="/error" element={<ErrorPage/>} />
            <Route path="/all-packages" element={<ViewAll/>} />
            <Route path="*" element={<Navigate to="/" replace />} />
         </Routes>
      {Shownnav() && <Footer/>}
    </div>
  );
}

export default App;
