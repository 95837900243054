import React, { useState } from 'react'
import './AndamanSection.css'
import AndaImg from '../../Assets/PackageImages/ANDA-1.jpg'
import { Container } from 'react-bootstrap'
import AndamanGroupTour from '../AndamanGroupTour/AndamanGroupTour'
import AndamanCustomizedTour from '../AndamanCustomizedTour/AndamanCustomizedTour'
import {Button} from '@mui/material';

export default function AndamanSection() {
  const [modalShow, setModalShow] = useState(false)
  const [cusmodalShow , setcusmodalShow] = useState(false)
  const handleGroup = () => {
    setModalShow(true)
  }

  const handleCustomized = () =>{
   setcusmodalShow(true)
  }
  
  return (
    <div>
        <h1 className='package-header mt-3'>Our Top Attraction</h1>
        <AndamanGroupTour
        show={modalShow}
        onHide={() => setModalShow(false)}
        />
        <AndamanCustomizedTour 
        show={cusmodalShow}
        onHide={() => setcusmodalShow(false)}/>
        <Container className='andaman-container mb-5 d-flex justify-content-center'>
          <div className="andaman-section-grid">
            <div className="andaman-section-card">
              <img src={AndaImg} alt="blog" className='w-100'/>
              <div className="andaman-content">
                <h2 className='text-white destination-text-andaman'>Andaman Islands</h2>
                  <Button variant="contained" className='fs-4 fw-semibold text-capitalise mx-1' size="large" onClick={handleGroup} color="success">Group Tour</Button>
                  <Button variant="contained" className='fs-4 fw-semibold text-capitalise mx-1' size="large" onClick={handleCustomized} color="success">Custom Tour</Button>
              </div>
            </div>
          </div>
        </Container>
    </div>
  )
}
