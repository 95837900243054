import React from 'react'

export default function ErrorPage(props) {
    const handleRedirect = () => {
        window.location.href = '/'
    }
  return (
    <div>
        <div className="error-page">
            <h2>Oops! Something went wrong.</h2>
            <p>Page returned with status code {props.statusCode}</p>
            <button className='btn text-capitalize' onClick={handleRedirect}>Click Here To return to Page</button>
        </div>
  </div>
);
}

