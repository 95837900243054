import React from 'react'
import './ImageGallery.css'

export default function ImageGallery(props) {
  return (
    <div className="gallery">
            <div className="image-box image-active">
                <img  src={props.A1} alt="A=1" />
            </div>
            <div className="image-box">
                <img  src={props.A2} alt="A=1" />
            </div>
            <div className="image-box">
                <img  src={props.A3} alt="A=1" />
            </div>
            <div className="image-box">
                <img  src={props.A4} alt="A=1" />
            </div>
            <div className="image-box">
                <img  src={props.A5} alt="A=1" />
            </div>
    </div>
  )
}
