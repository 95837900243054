import React , {useEffect, useState} from 'react';
import { Container, Spinner } from 'react-bootstrap';
import axios from 'axios';
import host from '../../Assets/Host';
import PackageSearchCard from '../../Components/PackageSearchCard/PackageSearchCard';
import { FaSearch } from "react-icons/fa";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';
import './SearchResult.css';
import {Button} from '@mui/material';
//  height: 427 px width: 640 px

export default function SearchResult() {

  const [term, setterm] = useState("");
  const [DataReceived, setDataReceived] = useState(false)
  const [Data, setData] = useState([]);
  const [images, setimages] = useState({})
  const [packageimages, setpackageimages] = useState({});
  const [isLoading, setisLoading] = useState(true)
  const [searchlist, setsearchlist] = useState([])
  const [receivedSearch, setreceivedSearch] = useState(false)
  const [resultMessage, setresultMessage] = useState('')
  const [receivedViewAll, setreceivedViewAll] = useState(false)

  const getPackages = async () => {
    try
    {
      const Authorization = localStorage.getItem('access_token')
      await axios.get(`${host}/package/` , {withCredentials: true, headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setData(response.data)})
      
    }
    catch(e){
      setData(e);
    }
    
  };

  const getimages = async () => {
    try {
      const Authorization = localStorage.getItem('access_token');
      const response = await axios.get(`${host}/images/`, { withCredentials: true, headers: { Authorization: "Bearer " + Authorization, 'Content-Type': 'application/json' } });
      setimages(response.data);
    } catch (e) {
      setimages([]);
      console.error(e);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const fetchData = async () => {
        setisLoading(true);
        await getPackages();
        await getimages();
        setDataReceived(true);
        setisLoading(false);
      };
      fetchData();
    }, 2000);
    return () => clearTimeout(timer);
  }, []
  );

  useEffect(() => {
    if (DataReceived && images.length > 0) {
      const updatedPackageImages = {};
      for (let i = 0; i < images.length; i++) {
        for (let j = 0; j < Data.length; j++) {
          if (Data[j]['holiday_package_id'] === images[i]['destination_id']) {
            updatedPackageImages[Data[j]['holiday_package_id']] = images[i]['image'];
          }
        }
      }
      setpackageimages(updatedPackageImages);
    }
  }, [Data, images, DataReceived]);

  useEffect(() => {
      if (receivedSearch) {
      if (searchlist.length > 0) {
        setresultMessage('Search Results')
      // console.log(images)
      } else {
        setresultMessage('No matches found')
      }
    } 
  }, [receivedSearch, searchlist]
  );

  const submitHandler = (e) => {
    setDataReceived(false)
    const data_new = []
    e.preventDefault();
    Data.forEach((data)=> {
      let text = data.destination;
      let search_text = term;
      if (search_text !== ''){let regex = new RegExp(search_text, 'gi');
        let matches = text.match(regex);
        if (matches !== null){data_new.push(data)}}
        setsearchlist(data_new)
        setreceivedSearch(true)
    })
  }

  const handleViewAll = () => {
    setreceivedViewAll(true)
    window.location.href = '/all-packages'
  }

  return (
    <div>
      <Container className='mt-3 mb-3'>
          <h2 className='package-header mb-3'>Our Holiday Packages</h2>
            <div className='border h-25 w-100 bg-dark mt-1 mb-3' />
            <div className="package-search mb-4">
              <form onSubmit={submitHandler} className='rounded'>
                <input type="text" value={term} placeholder='Search Your Package' onChange={(e)=>{ setterm(e.target.value) }}/>
                <button type='submit' className='bg-success text-white'><FaSearch /></button>
              </form>
            </div>
              {isLoading &&  <Spinner animation="grow" />}
              {DataReceived ?
              <div>
                  <Swiper
                    effect={'coverflow'}
                    spaceBetween={2}
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: true,
                    }}
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    style={{ height: "300px" }}
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                  >
                  {DataReceived && Data.map((item, index) => { return (
                      <SwiperSlide key={index}>
                          <PackageSearchCard 
                          itemImage={`${host}${packageimages[item.holiday_package_id]}`}
                          destination={item.destination}
                          adult_price = {item.adult_price}
                          children_price = {item.children_price}
                          itemDescription='Lorem ipsum dolor sit amet consectetur adipisicing elit.'/>
                      </SwiperSlide>
                        )})
                      }
                  </Swiper>
                  <Button variant='contained' color='success' className='mt-1 fs-5 border-0 fw-semibold' onClick={handleViewAll}>View all</Button>
                  <div className="main-div-packages">
                    <div className="center-div-packages" id='center-packages'></div>
                  </div>
              </div>
                : null}
              {receivedViewAll}
              <h1>{resultMessage}</h1>
              <div className="row">
              {receivedSearch && searchlist.map((item, index) => { return(
                  <div className="col-lg-6">
                    <PackageSearchCard key={index}
                      itemImage={`${host}${packageimages[item.holiday_package_id]}`}
                      destination={item.destination}
                      adult_price = {item.adult_price}
                      children_price = {item.children_price}
                      itemDescription='Lorem ipsum dolor sit amet consectetur adipisicing elit.'/>
                    </div>
                )})}
              </div>
              {receivedSearch && <button className='mt-1 btn btn-dark' onClick={()=>{setDataReceived(true); setreceivedSearch(false); setresultMessage(''); setterm('')}}>Go Back</button>}
      </Container>
    </div>
  )
}
