import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import host from '../../Assets/Host';
import { useParams } from 'react-router-dom'
import ErrorPage from '../ErrorPage/ErrorPage';
import { Container, Form , Row , Col, Modal, Table  } from 'react-bootstrap';
import A1 from '../../Assets/PackageImages/Andaman/A-1.jpeg'
import A2 from '../../Assets/PackageImages/Andaman/A-2.jpeg'
import A3 from '../../Assets/PackageImages/Andaman/A-3.jpeg'
import A4 from '../../Assets/PackageImages/Andaman/A-4.jpeg'
import A6 from '../../Assets/PackageImages/Andaman/A-6.jpeg'
import ImageGallery from '../../Components/ImageGallery/ImageGallery';
import { RiArrowRightDoubleFill } from "react-icons/ri";
import './AndamanGroupPage.css'
import {Button} from '@mui/material';

export default function AndamanGroupPage()  {
    const { date } = useParams();
    const Authorization = localStorage.getItem('access_token')
    const [show, setShow] = useState(false);
    const [currentTab, setCurrentTab] = useState('ite')
    const [Data , setData] = useState(null)
    const [bookReceived, setBookReceived] = useState(false)
    const [pageHasError, setpageHasError] = useState(false)
    const [paymentStatus, setpaymentStatus] = useState(false)
    const [pagestatus ,setpagestatus] = useState(null)
    const [hoveredDay, setHoveredDay] = useState(null);

    const handleMouseEnter = (dayKey) => {
        setHoveredDay(dayKey);
    };

    const handleMouseLeave = () => {
        setHoveredDay(null);
    };
    const handleClose = () => {
        setShow(false);
        const bookdata = {
            username : localStorage.getItem('user'),
            booking_id :  Math.floor(1000000000000000 + Math.random() * 9000000000000000),
            customer_name : FormData.name,
            customer_email : FormData.email,
            customer_phone : FormData.phone,
            head_count : FormData.persons,
            trip_date : reverseDateFormat(date),
            total_amount : Data.per_person_cost*parseFloat(FormData.persons),
            booking_amount : calculatePercentage(35, Data.per_person_cost*parseFloat(FormData.persons)),
            package_name : '6N/7D',
            payment_status: paymentStatus
        }
        console.log(bookdata)
       saveBooking(bookdata);
       setBookReceived(true);
    };

    const [FormData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        persons: '',
        date: date
    })

    function reverseDateFormat(dateString) {
        const parts = dateString.split('-');
        if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            }
        return dateString;
        }

    function calculatePercentage(part, total) {
        if (total === 0) {
          return 0;
        }
        return (part / 100) * total;
      }

    const getPackageData = useCallback(async () => {
        try
        {
        
        await axios.get(`${host}/package/andaman/group`, {params: {date: date}, withCredentials: true, headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setData(response.data); setpagestatus(response.status);}) 
        }
        catch(e){
          setData(e)
          setpageHasError(true)
        }
            
      }, [Authorization, date]);

    async function saveBooking(bookData){
        try{
        await axios.post(`${host}/package/booking/`,bookData ,{headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setpagestatus(response.status); setpaymentStatus(true)});
        } catch(e){
            setpageHasError(true);
            console.log(e)
        }
    }   

    useEffect(() => {
        const timer = setTimeout(() => {
            getPackageData();
        }, 5);
        return () => clearTimeout(timer);
      },[getPackageData]
      );

    const handleiteclick = () => {
        setCurrentTab('ite');
    }

    const handleSubmitBooking = (e) => {
        e.preventDefault();
        setShow(true);
        
        // setbookdetails(bookdata)
    }

    

    const handleOnInputChange = (e) =>{
        const {name, value} = e.target;
        setFormData({
            ...FormData,
            [name] : value 
        })

    }

  return (
    <div>
    {pageHasError ? <ErrorPage statusCode={pagestatus}/> : 
    <div>
        <ImageGallery A1={A1} A2={A2} A3={A3} A4={A4} A5={A6}/>
        {Data !== null ?
        <div className="wrapper d-flex flex-wrap justify-content-between">
            <div className="itinerary-div shadow p-2 mb-3 bg-body-tertiary rounded ms-3 h-75 mt-3 border border-3">
                <h2 className='poppins-bold'>SPECIAL HOLIDAY PACKAGE  6N/07 DAYS</h2>
                <span className='style-script-regular'>Trip Date : {date}</span>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <span className={currentTab === 'ite' ? 'nav-link link-success active fs-4 fw-bold text-capitalize' : 'nav-link link-dark fs-5 fw-bold text-capitalize'} style={{cursor: 'pointer'}} onClick={handleiteclick}>itinerary</span>
                    </li>
                    <li className="nav-item">
                        <span className={currentTab === 'in-ex' ? 'nav-link link-success active fs-4 fw-bold text-capitalize' : 'text-dark nav-link fs-5 fw-bold text-capitalize'} style={{cursor: 'pointer'}} onClick={() => {setCurrentTab('in-ex')}}>Inclusions And Exclusions</span>
                    </li>
                </ul>
            </div>
            <div className="book-div shadow p-2 mb-5 bg-body-tertiary rounded me-3 mt-3 border border-3 h-25 pt-3 align-items-center">
                <h4 className='mt-0'>Fill Booking Details</h4>
                <Form onSubmit={handleSubmitBooking}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control type="text" name="name" placeholder="Name" value={FormData.name} onChange={handleOnInputChange}required/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Control type="tel" placeholder='phone' name="phone" pattern='[1-9]{1}[0-9]{9}' onChange={handleOnInputChange} required/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control type="email" placeholder="Email" name="email" onChange={handleOnInputChange} required/>
                    </Form.Group>
                    
                    <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="persons">
                            <Form.Control type="number" name='persons' onChange={handleOnInputChange} placeholder='Total Persons' min="1" max={Data.available_seats} required/>
                        </Form.Group>
                        </Col>
                        <Col className='me-2 p-0'>
                            Trip Date<br/>{date}
                        </Col>
                    </Row>
                    <Button variant='contained' color='success' type='submit'>Book Now!</Button>
                </Form>
            </div>
        </div>
        : ''} 
        <div className=" d-flex justify-content-between"> 
            {currentTab === 'ite' && 
            <div className="itenary-content">
                {Data !== null && Object.keys(Data.itenary).map((dayKey, index) => (
                <div 
                    key={dayKey}
                    className="shadow p-2 bg-body-tertiary rounded border border-3"
                    onMouseEnter={() => handleMouseEnter(dayKey)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='container d-flex justify-content-start'>
                        <h4 className='fw-semibold style-script-regular me-2'>Day {index + 1} - </h4>
                        {hoveredDay !== dayKey  && (
                            <p className='ite-content fw-bold text-success'>{Data.itenary[dayKey].header}</p>
                        )}
                        {hoveredDay === dayKey && (
                            <p className='fw-semibold text-info-emphasis'>{Data.itenary[dayKey].content}</p>
                        )}
                    </div>
                </div>
            ))}
{/* 
                <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3">
                    {Data !== null && 
                        <div className='container {ishovered}'>
                            <h4 className='fw-semibold style-script-regular'> Day 01</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_01.header}</p>
                                <p className=''>{Data.itenary.DAY_01.content}</p>
                        </div>}
                </div>
                <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3 d-flex justify-content-between">
                    {Data !== null && 
                        <div className='container'>
                            <h4 className='fw-semibold style-script-regular'> Day 02</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_02.header}</p>
                                <p className=''>{Data.itenary.DAY_02.content}</p>
                        </div>}
                </div>
                <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3 d-flex justify-content-between">
                    {Data !== null && 
                        <div className='container'>
                            <h4 className='fw-semibold style-script-regular'> Day 03</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_03.header}</p>
                                <p className=''>{Data.itenary.DAY_03.content}</p>
                        </div>}
                </div>
                <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3 d-flex justify-content-between">
                    {Data !== null && 
                        <div className='container'>
                            <h4 className='fw-semibold style-script-regular'> Day 04</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_04.header}</p>
                                <p className=''>{Data.itenary.DAY_04.content}</p>
                        </div>}
                </div>
                <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3 d-flex justify-content-between">
                    {Data !== null && 
                        <div className='container'>
                            <h4 className='fw-semibold style-script-regular'> Day 05</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_05.header}</p>
                                <p className=''>{Data.itenary.DAY_05.content}</p>
                        </div>}
                </div>
                <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3 d-flex justify-content-between">
                    {Data !== null && 
                        <div className='container'>
                            <h4 className='fw-semibold style-script-regular'> Day 06</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_06.header}</p>
                                <p className=''>{Data.itenary.DAY_06.content}</p>
                        </div>}
                </div>
                <div className="shadow p-2 mb-5 bg-body-tertiary rounded ms-3 border border-3 d-flex justify-content-between">
                    {Data !== null && 
                        <div className='container'>
                            <h4 className='fw-semibold style-script-regular'> Day 07</h4>
                                <p className='fw-bold'>{Data.itenary.DAY_07.header}</p>
                                <p className=''>{Data.itenary.DAY_07.content}</p>
                        </div>}
                </div> */}
            </div>}
            {currentTab === 'in-ex' && 
            <div className="itenary-content">
            <div className='shadow p-2 bg-body-tertiary rounded ms-3 border border-3'>
                <h4>Inclusions</h4>
                <ul className='list-group list-group-flush'>
                
                {Data.inclusions.map((item, index)=> {
                    return (
                        <li key={index} className='list-group-item d-flex justify-content-start'><RiArrowRightDoubleFill/> &nbsp;&nbsp;{item}</li>
                    )
                })}
                </ul>
                <hr/>
                <hr/>
                <h4>Exclusions</h4>
                <ul className='list-group list-group-flush'>
                {Data.exclusions.map((item, index)=> {
                    return (
                        <li key={index} className='list-group-item d-flex justify-content-start align-items-center'><RiArrowRightDoubleFill/>       &nbsp;{item}</li>
                    )
                })}
                </ul>
            </div>
            </div>}
            <div className="book-div shadow p-2 mb-5 bg-body-tertiary rounded me-3 border border-3 h-50 pt-3 align-items-center">
                <p><span className='fw-bold fs-4'>{Data !== null && `Rs ${Data.per_person_cost.toLocaleString()}`}</span>/person</p>
                <p><span className='fw-semibold fs-5'>{bookReceived && `Amount Payable :Rs ${(calculatePercentage(35, Data.per_person_cost*parseFloat(FormData.persons)).toLocaleString())}`}</span></p>
                <Button className="fw-semibold" variant='contained' color='success'>Proceed For Payment</Button>
            </div>
        </div>
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header  className='justify-content-center' closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className='fs-1 text-capitalize'>Booking Details</span>
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Container className='d-flex justify-content-center align-items-center'>
                        <Table striped bordered hover variant="success" className='w-100'>
                        <tbody className='text-center'>
                            <tr>
                                <td>Name </td>
                                <td>{FormData.name}</td>
                            </tr>
                            <tr>
                                <td>Phone </td>
                                <td>{FormData.phone}</td>
                            </tr>
                            <tr>
                                <td>Email </td>
                                <td>{FormData.email}</td>
                            </tr>
                            <tr>
                                <td>Total Persons </td>
                                <td>{FormData.persons}</td>
                            </tr>
                            <tr>
                                <td>Trip Date </td>
                                <td>{FormData.date}</td>
                            </tr>
                            <tr>
                                <td>Total Trip Amount </td>
                                <td>
                                    {Data !== null && <>Rs {(Data.per_person_cost*parseFloat(FormData.persons)).toLocaleString()}</>}
                                </td>
                            </tr>
                            <tr>
                                <td>Total Booking Amount </td>
                                <td>
                                {Data !== null && <>Rs {(calculatePercentage(35, Data.per_person_cost*parseFloat(FormData.persons)).toLocaleString())}</>}
                                </td>
                            </tr>
                        </tbody>
                        </Table>
                    </Container>
                </Modal.Body>
            <Modal.Footer>
            {/* <Button onClick={handleClose}>
                Close
            </Button> */}
            <Button onClick={handleClose}>
                Confirm Booking
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
    }
    </div>
  )
}
