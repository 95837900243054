import React, { useEffect, useState } from 'react'
import './NavBar.css';
import { Navbar, Nav,  Alert, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import HomeLogo from '../../Assets/Icons/Home.png';
import HomeLogoLight from '../../Assets/Icons/Home-light.png';
import FlightLogoLight from '../../Assets/Icons/airplane-light.png';
import FlightLogo from '../../Assets/Icons/airplane.png';
import HotelLogo from '../../Assets/Icons/Hotel.png';
import HotelLogoLight from '../../Assets/Icons/Hotel-light.png';
import BusLogoLight from '../../Assets/Icons/Bus-light.png';
import BusLogo from '../../Assets/Icons/Bus.png';
import HolidayLogoLight from '../../Assets/Icons/Holiday-light.png';
import HolidayLogo from '../../Assets/Icons/Holiday.png';
import NavLogo from '../../Assets/Icons/nav-logo.png';
import User from '../../Assets/Icons/user.png';
import { CiLogin } from "react-icons/ci";
import axios from 'axios';
import host from '../../Assets/Host';

export default function NavBar({isTop}) {
    const [isauthorized, setisauthorized] = useState(false)
    const [logoutSuccess, setlogoutSuccess] = useState(true)
    const [errorMessage, seterrorMessage] = useState('')
    const [pageTrack, setpageTrack] = useState('home')
    let user = localStorage.getItem('user')
    const location = useLocation();

    const UserTitle = ({ user, logo }) => (
        <div className="d-flex align-items-center">
          <img src={logo} alt="Holiday Logo" style={{ width: '25px', height: '25px', marginRight: '5px' }} />
          <span>{user}</span>
        </div>
      )

    useEffect(() => {
        if (localStorage.getItem('access_token') !== null){
            setisauthorized(true)
        }
        if (location.pathname === '/flights'){
            setpageTrack('flights')
        } else if (location.pathname === '/hotels'){
            setpageTrack('hotels')
        }
        else if (location.pathname === '/buses'){
            setpageTrack('buses')
        }
        else if (location.pathname === '/holidays'){
            setpageTrack('holidays')
        }
        else{
            setpageTrack('home')
        }

    }, [location]);

    
    const Logout = async () => {
          try {
            const refresh_token = localStorage.getItem('refresh_token')
            const auth_token = localStorage.getItem('access_token')
            await axios.post(`${host}/logout/`,{refresh_token: refresh_token} ,{headers: {Authorization: `Bearer ${auth_token}`}});
            localStorage.clear();
            axios.defaults.headers.common['Authorization'] = null;
            window.location.href = '/'
            } catch (e) {
                setlogoutSuccess(false);
                if (e.request) {
                    // Request was made but no response was received
                    seterrorMessage('Network Error: No response from server.');
                  }
                else {
                    // Something else happened in setting up the request
                    seterrorMessage(`Error: ${e.message}`);
                  }
            }
          ;
     }

  return (
    <div>
      <Navbar expand="lg" className='postion-absolute w-100 z-3 p-0' id={isTop ? 'navbar-top' : 'nav-id'} fixed="top">
            {logoutSuccess === false && <Alert key='danger' variant='danger' dismissible>
            {errorMessage}
            </Alert>}
            <Navbar.Brand className='text-light'>
                <Link to='/' className='ms-3 text-decoration-none text-light d-flex'>
                    <img src={NavLogo} alt="logo" className='ms-2 me-1'/>Trip Holiday Travels
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' className='text-light'/>
            <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='ms-5 justify-content-center'>
                    <Link to='/' className={pageTrack === 'home' ? ' nav-link text-light' : 'text-light nav-link'}>
                    {pageTrack === 'home' ? <img className="me-2" alt="logo"  src={HomeLogoLight} width={30} height={30}/> : <img className="me-2" alt="logo"  src={HomeLogo} width={27} height={27}/>}
                    Home
                    </Link>
                </Nav>
                <Nav className='ms-5 justify-content-center'>
                    <Link to='/flights' className={pageTrack === 'flights' ? ' nav-link text-light' : 'text-light nav-link'}>
                        {pageTrack === 'flights' ? <img className="me-2" alt="logo"  src={FlightLogoLight} width={30} height={30}/> : <img className="me-2" alt="logo"  src={FlightLogo} width={27} height={27}/>}
                        Book Flights
                    </Link>
                </Nav>
                <Nav className='ms-5 justify-content-center'>
                    <Link to='/hotels' className={pageTrack === 'hotels' ? ' nav-link text-light' : 'text-light nav-link'}>
                        {pageTrack === 'hotels' ? <img className="me-2" alt="logo"  src={HotelLogoLight} width={30} height={30}/> : <img className="me-2" alt="logo"  src={HotelLogo} width={27} height={27}/>}
                        Book Hotels
                    </Link>
                </Nav>
                <Nav className='ms-5  justify-content-center'>
                    <Link to='/buses' className={pageTrack === 'buses' ? ' nav-link text-light' : 'text-light nav-link'}>
                        {pageTrack === 'buses' ? <img className="me-2" alt="logo"  src={BusLogoLight} width={30} height={30}/> : <img className="me-2" alt="logo"  src={BusLogo} width={27} height={27}/>}
                        Book Buses
                    </Link>
                </Nav>
                <Nav className='ms-4  justify-content-center'>
                    <Link to='/holidays' className={pageTrack === 'holidays' ? ' nav-link text-light' : 'text-light nav-link'}>
                        {pageTrack === 'holidays' ? <img className="me-2" alt="logo"  src={HolidayLogoLight} width={30} height={30}/> : <img className="me-2" alt="logo"  src={HolidayLogo} width={27} height={27}/>}
                        Book Holidays
                    </Link>
                </Nav>
                {!isauthorized && <Nav className=' justify-content-center'>
                    <Link to='/login' className='text-light nav-link'>
                        <CiLogin/>
                        Login
                    </Link>
                </Nav>}
                {isauthorized && <Nav className='ms-1 mt-2 justify-content-center'>
                    <NavDropdown className='nav-user text-uppercase fw-semibold' title={<UserTitle user={user} logo={User} />} id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.2">
                            Account Details
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={Logout} className=''>
                            Log Out
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>}
            </Navbar.Collapse>

        </Navbar>
    </div>
  )
}
