import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom';
import host from '../../Assets/Host';
import ErrorPage from '../ErrorPage/ErrorPage';
import { Form , Row , Col, Modal} from 'react-bootstrap';
import axios from 'axios';
import ImageGallery from '../../Components/ImageGallery/ImageGallery';
import A1 from '../../Assets/PackageImages/Andaman/A-1.jpeg'
import A2 from '../../Assets/PackageImages/Andaman/A-2.jpeg'
import A3 from '../../Assets/PackageImages/Andaman/A-3.jpeg'
import A4 from '../../Assets/PackageImages/Andaman/A-4.jpeg'
import A6 from '../../Assets/PackageImages/Andaman/A-6.jpeg'
import { GoDotFill } from "react-icons/go";

export default function AndamanCustomizedPage() {

  const { id } = useParams();

  const [Data, setData] = useState(null)
  const [pageHasError, setpageHasError] = useState(false)
  const Authorization = localStorage.getItem('access_token')
  const [pagestatus ,setpagestatus] = useState(null)
  const [currentTab, setcurrentTab] = useState('ite')
  const [show, setShow] = useState(false);
  const [bookID, setbookID] = useState(null)
  const [FormData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    persons: '',
    date: '',
    tour_name: ''
})
const [hoveredDay, setHoveredDay] = useState(null);

    const handleMouseEnter = (dayKey) => {
        setHoveredDay(dayKey);
    };

    const handleMouseLeave = () => {
        setHoveredDay(null);
    };

  const getCustomPackage = useCallback(async () => {
    try
    {
    
    await axios.get(`${host}/package/andaman/customizedupdate`, {params: {id_: id}, withCredentials: true, headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setData(response.data); setpagestatus(response.status);}) 
    }
    catch(e){
      setData(e)
      setpageHasError(true)
      console.log(e)
    }
        
  }, [Authorization, id]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getCustomPackage();
    }, 5);
    return () => clearTimeout(timer);
  },[getCustomPackage]
  );

  async function saveBooking(bookData){
    try{
    await axios.post(`${host}/package/booking/customized/`,bookData ,{headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setpagestatus(response.status);});
    } catch(e){
        setpageHasError(true);
        console.log(e)
    }
  } 
  const handleOnInputChange = (e) =>{
    const {name, value} = e.target;
    setFormData({
        ...FormData,
        [name] : value 
    })

  }

  const handleSubmitBooking = (e) => {
    e.preventDefault();
    const bookdata = {
      username : localStorage.getItem('user'),
      tour_type : Data.tour_type,
      tour_name : Data.name,
      booking_id :  Math.floor(1000000000000000 + Math.random() * 9000000000000000),
      customer_name : FormData.name,
      customer_email : FormData.email,
      customer_phone : FormData.phone,
      head_count : FormData.persons,
      trip_date : FormData.date
  } 
    setbookID(bookdata.booking_id)
    saveBooking(bookdata)
    setShow(true);
    
}
  
  return (
    <div>
      <ImageGallery A1={A1} A2={A2} A3={A3} A4={A4} A5={A6} />
      {pageHasError ? <ErrorPage statusCode={pagestatus} /> : 
      <div>
        {Data !== null ? 
            <div className="p-2 mb-1 bg-body-tertiary rounded ms-3 h-75 mt-2">
                <h3 className='mochiy-pop-p-one-regular text-capitalize'>{Data.name}<br/>  {Data.tour_type}</h3>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <span className={currentTab === 'ite' ? 'nav-link link-success active fs-5 fw-bold text-capitalize' : 'nav-link link-dark fs-5 fw-bold text-capitalize'} style={{cursor: 'pointer'}} onClick={() => {setcurrentTab('ite')}}>itinerary</span>
                    </li>
                    <li className="nav-item">
                        <span className={currentTab === 'in-ex' ? 'nav-link link-success active fs-5 fw-bold text-capitalize' : 'text-dark nav-link fs-5 fw-bold text-capitalize'} style={{cursor: 'pointer'}} onClick={() => {setcurrentTab('in-ex')}}>Inclusions And Exclusions</span>
                    </li>
                </ul>
          </div>
          : ''}
          <div className="content-wrapper d-flex justify-content-between"> 
            {currentTab === 'ite' && 
            <div className="itenary-content">
              {Data !== null && Object.keys(Data.itenary).map((dayKey, index) => (
                <div 
                    key={dayKey}
                    className="shadow p-2 bg-body-tertiary rounded border border-3"
                    onMouseEnter={() => handleMouseEnter(dayKey)}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='container d-flex justify-content-start'>
                        <h4 className='fw-semibold style-script-regular me-2'>Day {index + 1} - </h4>
                        {hoveredDay !== dayKey  && (
                            <p className='ite-content fw-bold text-success'>{Data.itenary[dayKey].header}</p>
                        )}
                        {hoveredDay === dayKey && (
                            <p className='fw-semibold text-info-emphasis'>{Data.itenary[dayKey].content}</p>
                        )}
                    </div>
                </div>
            ))}
                {/* {Data !== null &&  Object.entries(Data.itenary).map(([key, value]) => {return (
                  <div className="shadow p-2 bg-body-tertiary rounded ms-3 border border-3" key={key}>
                      <div className='container'>
                          <h4 className='fw-semibold style-script-regular'>{key}</h4>
                              <p className='fw-bold'>{value.header}</p>
                              <p className=''>{value.content}</p>
                      </div>
                  </div>)})} */}
                </div>
            }
            {currentTab === 'in-ex' && 
            <div className="itenary-content">
            <div className='shadow p-2 bg-body-tertiary rounded ms-3 border border-3'>
                <h4>Inclusions</h4>
                <ul className='list-group list-group-flush'>
                
                {Data.inclusions.map((item, index)=> {
                    return (
                        <li key={index} className='list-group-item d-flex justify-content-start'><GoDotFill/> &nbsp;&nbsp;{item}</li>
                    )
                })}
                </ul>
                <hr/>
                <hr/>
                <h4>Exclusions</h4>
                <ul className='list-group list-group-flush'>
                {Data.exclusions.map((item, index)=> {
                    return (
                        <li key={index} className='list-group-item d-flex justify-content-start align-items-center'><GoDotFill/>&nbsp;{item}</li>
                    )
                })}
                </ul>
            </div>
            </div>}
            <div className="book-div-custom shadow p-2 mb-2 bg-body-tertiary rounded me-3 border border-3 h-25 pt-3 align-items-center position-relative justify-content-end">
                <h4 className='mt-0'>Fill Booking Details</h4>
                <Form onSubmit={handleSubmitBooking}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control type="text" name="name" placeholder="Name" value={FormData.name} onChange={handleOnInputChange}required/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Control type="tel" placeholder='phone' name="phone" pattern='[1-9]{1}[0-9]{9}' onChange={handleOnInputChange} required/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control type="email" placeholder="Email" name="email" onChange={handleOnInputChange} required/>
                    </Form.Group>
                    
                    <Row>
                        <Col>
                        <Form.Group className="mb-3" controlId="persons">
                            <Form.Control type="number" name='persons' onChange={handleOnInputChange} placeholder='Total Persons' required/>
                        </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3" controlId="date">
                      <Form.Label column sm={4}>
                        Trip Date
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control type="date" name='date' onChange={handleOnInputChange} placeholder='Trip Date' required/>
                      </Col>
                    </Form.Group>
                    <button className='btn' type='submit'>Enquire Now!</button>
                </Form>
            </div>
            <Modal show={show} onHide={(e) => {setShow(false); window.location.reload();}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header  className='justify-content-center' closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <span className='fs-1 text-capitalize'>Booking Details</span>
                </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <p>Your Enquiry has been sent to our representative with enquiry number {bookID}</p>
                    <p>Our Representative will get back to you shortly!</p>
                </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
        </div>
      </div>
      
      }
    </div>
  )
}
