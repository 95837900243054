import React , { useEffect, useState }  from 'react'
import axios from 'axios';
import host from '../../Assets/Host';
import { Modal, Button , Table, Container  } from 'react-bootstrap';
import { TbChristmasBall } from "react-icons/tb";
import './AndamanCustomizedTour.css';

export default function AndamanCustomizedTour(props) {

    const [Data, setData] = useState(null)
    const [received, setreceived] = useState(false)
    async function getPackages(){
        try
        {
        const Authorization = localStorage.getItem('access_token')
        await axios.get(`${host}/package/andaman/customized`, {withCredentials: true, headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setData(response.data); setreceived(true)} )
        }
        catch(e){
          setData(e)
        }
        
      } 

    useEffect(() => {
      const timer = setTimeout(() => {
        getPackages();
      }, 500);
      return () => clearTimeout(timer);
    }, []
    );

  return (
    <div>
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        scrollable>
          <Modal.Header  className='justify-content-center bg-success text-white'>
            <Modal.Title id="contained-modal-title-vcenter">
                <span className='style-script-regular fs-1 text-capitalize'>Select Your Package</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <h3 className='d-flex justify-content-center'><span className='style-script-regular text-capitalize'></span></h3> */}
            <Container className='d-flex justify-content-center align-items-center'>
              <Table striped bordered hover variant="success" className='w-50'>
                <h4 className='fw-bold' key='header'>Available Packages</h4>
                <tbody className='text-start'>
                  {received && Data.map((item,index) => { return(
                    <tr>
                      <td>
                        <a href={`/customized/${item.id}`} className='roboto-bold-italic text-capitalize fw-semibold link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' key={index}><TbChristmasBall/> {item.name}</a>
                      </td>
                    </tr>
                      )})}
                </tbody>
              </Table>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
    </Modal>
        
    </div>
  )
}
