import React, { useState, useEffect } from 'react'
import axios from 'axios';
import host from '../../Assets/Host';
import { Container } from 'react-bootstrap';
import SecondHeroSection from '../SecondHeroSection/SecondHeroSection';
import PackageSearchCard from '../PackageSearchCard/PackageSearchCard';
import { FaSearch } from "react-icons/fa";
import './ViewAll.css';


export default function ViewAll() {
    const [term, setterm] = useState("");
    const [Data, setData] = useState(null)
    const [images, setimages] = useState({})
    const [DataReceived, setDataReceived] = useState(false)
    const [searchlist, setsearchlist] = useState([])
    const [packageimages, setpackageimages] = useState({})
    const [receivedSearch, setreceivedSearch] = useState(false)
    const [resultMessage, setresultMessage] = useState('')
    const [isLoading, setisLoading] = useState(true)
    //get all packages
    const getPackages = async () => {
      try
      {
        const Authorization = localStorage.getItem('access_token')
        await axios.get(`${host}/package/` , {withCredentials: true, headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setData(response.data)})
        
      }
      catch(e){
        setData(e);
      }
      
    };
  
    const getimages = async () => {
      try {
        const Authorization = localStorage.getItem('access_token');
        const response = await axios.get(`${host}/images/`, { withCredentials: true, headers: { Authorization: "Bearer " + Authorization, 'Content-Type': 'application/json' } });
        setimages(response.data);
      } catch (e) {
        setimages([]);
        console.error(e);
      }
    };
  
    useEffect(() => {
      const timer = setTimeout(() => {
        const fetchData = async () => {
          setisLoading(true);
          await getPackages();
          await getimages();
          setDataReceived(true);
          setisLoading(false);
        };
        fetchData();
      }, 2000);
      return () => clearTimeout(timer);
    }, []
    );
    
    useEffect(() => {
      if (DataReceived && images.length > 0) {
        const updatedPackageImages = {};
        for (let i = 0; i < images.length; i++) {
          for (let j = 0; j < Data.length; j++) {
            if (Data[j]['holiday_package_id'] === images[i]['destination_id']) {
              updatedPackageImages[Data[j]['holiday_package_id']] = images[i]['image'];
            }
          }
        }
        setpackageimages(updatedPackageImages);
      }
    }, [Data, images, DataReceived]);
  
    useEffect(() => {
        if (receivedSearch) {
        if (searchlist.length > 0) {
          setresultMessage('Search Results')
        // console.log(images)
        } else {
          setresultMessage('No matches found')
        }
      } 
    }, [receivedSearch, searchlist]
    );

    const submitHandler = (e) => {
        setDataReceived(false)
        const data_new = []
        e.preventDefault();
        Data.forEach((data)=> {
          let text = data.destination;
          let search_text = term;
          if (search_text !== ''){let regex = new RegExp(search_text, 'gi');
            let matches = text.match(regex);
            if (matches !== null){data_new.push(data)}}
            setsearchlist(data_new)
            setreceivedSearch(true)
        })
      }

  return (
    <div>
        <div className='view-all'> <SecondHeroSection HeroTitle='Our Packages'/></div>
        <Container className='mt-2'>
            <div className="package-search mb-4">
              <form onSubmit={submitHandler} className='rounded'>
                <input type="text" value={term} placeholder='Search Your Package' onChange={(e)=>{ setterm(e.target.value) }}/>
                <button type='submit' className='bg-success text-white'><FaSearch /></button>
              </form>
            </div>
            {!isLoading && <div className="row g-3">
                {DataReceived && Data.map((item, index) => {
                    return (<div className="col-lg-4 pack">
                        <PackageSearchCard 
                    itemImage={`${host}${packageimages[item.holiday_package_id]}`}
                    destination={item.destination}
                    adult_price = {item.adult_price}
                    children_price = {item.children_price}
                    itemDescription='Lorem ipsum dolor sit amet consectetur adipisicing elit.'/>
                    </div>)
                })}
                <h3>{resultMessage}</h3>
                {receivedSearch && searchlist.map((item, index) => { return(
                  <div className="col-lg-6">
                    <PackageSearchCard key={index}
                      itemImage={`${host}${packageimages[item.holiday_package_id]}`}
                      destination={item.destination}
                      adult_price = {item.adult_price}
                      children_price = {item.children_price}
                      itemDescription='Lorem ipsum dolor sit amet consectetur adipisicing elit.'/>
                    </div>
                )})} 
            </div>}
            {receivedSearch && <button className='mt-1 btn btn-dark w-25' onClick={()=>{setDataReceived(true); setreceivedSearch(false); setresultMessage(''); setterm('')}}>Go Back</button>}
        </Container>
    </div>
  )
}
