import React  , {useEffect} from 'react'
import './Holidays.css';
import SecondHeroSection from '../../Components/SecondHeroSection/SecondHeroSection';
import AndamanSection from '../../Components/AndamanSection/AndamanSection';
import host from '../../Assets/Host';
import axios from "axios";
import SearchResult from '../SearchResult/SearchResult';
import { Container } from 'react-bootstrap';


export default function Holidays() {
  useEffect(() => {
    if(localStorage.getItem('access_token') === null){            
        window.location.href = '/login'
    }
    else{
     (async () => {
       try {
         const Authorization = localStorage.getItem('access_token')
         await axios.get(   
                        `${host}/login/` ,{
                          withCredentials: true,
                         headers: {
                              Authorization : "Bearer " + Authorization,
                            'Content-Type': 'application/json'
                         }}
                       );
      } catch (e) {
        window.location.href = '/login'
      }
     })()};
 }, []);



  return (
    <div className='hoilday-body'>
      <div className="holiday-hero-section"><SecondHeroSection HeroTitle="Discover Your Perfect Getaway"/></div>
      <Container>
      <AndamanSection/>
      <SearchResult/>
      </Container>
    </div>
  )
}
