import React from 'react'
import { Card } from 'react-bootstrap'
import './PackageSearchCard.css'
import {Button} from '@mui/material';

function PackageSearchCard(props) {
  return (
      <Card className="package-card shadow h-100 overflow-hidden mx-1" key={props.key}>
        <Card.Img className='img-hover h-100' src={props.itemImage} />
        <Card.ImgOverlay>
          <Card.Title className='card-header text-uppercase fw-bold destination-text text-white'>
            {props.destination}
          </Card.Title>
            <div className="pack-btn">
              
              <Button variant="contained" className='fs-bold text-capitalise destination-text' color="success">Group Tour</Button> 
              
              <Button variant="contained" className='fs-bold text-capitalise destination-text' color="success">Custom Tour</Button> 
              
            </div>
        </Card.ImgOverlay>
      </Card>
  )
}

export default PackageSearchCard
