import React, { useEffect, useState } from 'react';
import axios from 'axios';
import host from '../../Assets/Host';
import { Modal, Table, Container  } from 'react-bootstrap';
import './AndamanGroupTour.css';
import CalendarLogo from '../../Assets/Icons/calendar.png';
import {Button} from '@mui/material';

export default function AndamanGroupTour(props) {

    const [Data, setData] = useState(null)
    const [tour, settour] = useState(null)
    const [received, setreceived] = useState(false)
    const [monthSelected, setmonthSelected] = useState(false)
    const monthList = ['January', 'February', 'March', 'April', 'May', 'June',
                        'July', 'August', 'September', 'October', 'November', 'December']

    function reverseDate(dateStr) {
      let givenDate = new Date(dateStr);

      let today = new Date();

      today.setHours(0, 0, 0, 0);

      if (givenDate > today)
        {
          // Split the date string by the dash "-"
          let parts = dateStr.split("-");
          
          // Reverse the array of date parts
          let reversedParts = parts.reverse();
          
          // Join the reversed parts with dashes "-"
          let reversedDateStr = reversedParts.join("-");
          
          return reversedDateStr;
        }
    }

    function getDates(Data_){
      if (Data_.length === 0){
        return (
          <h5>No Group tours for this month. Try Our Customized tours!</h5>
        )
      }
      let new_Data = []
      for (let i = 0 ; i < Data_.length ; i++){
        let reversed = reverseDate(Data_[i].available_dates)
        if (reversed){
          new_Data.push({date:reversed, seats: Data_[i].available_seats})
        }
      }
      return(
        new_Data.map((item,index) => { return(
          <tr key={index} className=''>
            <td><img src={CalendarLogo} alt="logo" style={{ width: '25px', height: '25px', marginRight: '8px' }}/><a className='link-dark link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover' href={`/date/${item.date}`}>{item.date}</a></td>
            <td>{item.seats}</td>
          </tr>
        )
      }
      )
      )
    }

    const handleMonth = (e) =>{
      e.preventDefault();
      setmonthSelected(true);
      const mon = parseInt(e.target.name, 10)
      const Tours = Data.filter(tour => new Date(tour.available_dates).getMonth() === mon);
      settour(Tours);
      //console.log(Tours);
      // console.log(Tours);
    }

    const handleClose = () =>{
      props.onHide();
      setmonthSelected(false);
    }

    const handleBack = () =>{
      setmonthSelected(false);
      settour(null);
    }

    async function getPackages(){
        try
        {
        const Authorization = localStorage.getItem('access_token')
        await axios.get(`${host}/package/andaman`, {withCredentials: true, headers: {Authorization : "Bearer " + Authorization,'Content-Type': 'application/json'}}).then(response => {setData(response.data); setreceived(true);})
        }
        catch(e){
          setData(e)
        }
        
      }

    useEffect(() => {
      const timer = setTimeout(() => {
        getPackages();
      }, 500);
      return () => clearTimeout(timer);
    }, []
    );

  return (
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        scrollable>
          <Modal.Header  className='justify-content-center bg-success text-white'>
            <Modal.Title id="contained-modal-title-vcenter">
                <span className='roboto-bold-italic fs-2 text-capitalize'>Andaman Group Tour</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4 className='d-flex justify-content-center'><span className='style-script-regular fs-3 text-capitalize'>Select Month</span></h4>
            <Container className='d-flex justify-content-center'>
            {!monthSelected ? <Table striped bordered hover variant="success" className='w-50'>
                <thead className='text-center'>
                  <tr>
                    <th>Select Month</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {monthList.map((item, index) => {return (
                      <tr key={index} className=''>
                      <td><Button className="ms-2 mb-2" name={index} variant="contained" color="success" key={index} onClick={handleMonth}>{item}</Button></td>
                      </tr>
                    )})}
                </tbody>
              </Table>
              : <Table striped bordered hover variant="success" className='w-50'>
                <thead className='text-center'>
                  <tr> 
                    <th>Dates</th>
                    <th>Seats Available</th>
                  </tr>
                </thead>
                <tbody className='text-center'>
                  {received && getDates(tour)}
                </tbody>
              </Table> }
            </Container>
          </Modal.Body>
          <Modal.Footer className='justify-content-end'>
            {monthSelected && <Button  className='mx-1' variant="contained" onClick={handleBack} color="secondary">Back</Button>}
            <Button  className='mx-1' variant="contained" onClick={handleClose} color="secondary">Close</Button>
          </Modal.Footer>
    </Modal>
        
  )
}
